import React, { useState } from "react";
import "./App.css";
//Components
import Form from "./components/Form";
import HashLoader from "react-spinners/HashLoader";

//Utils
import axios from "./api/axiosInstance";
function App() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [err, setErr] = useState(null);
  const [totalTax, setTotal] = useState(0);
  const [uniqueTaxes, setUniqueTaxes] = useState([]);
  const submitForm = async (
    e,
    streetAddress,
    city,
    zipCode,
    country,
    productType,
    stateCode
  ) => {
    try {
      e.preventDefault();

      setErr(null);
      setResult(null);
      setLoading(true);
      const response = await axios.post("/calculate-tax", {
        address: streetAddress,
        city: city,
        zip: zipCode,
        country: country,
        productType: productType,
        stateCode,
      });

      const taxes = response.data.invoice.lineItems[0].taxes;
      const uniqueTaxes = taxes.filter(
        (tax, index, self) =>
          index ===
          self.findIndex((t) => t.taxAuthorityId === tax.taxAuthorityId)
      );

    //? If totalTaxLine is 0 set all tax rates to 0
      // const totalTaxLine = Math.round(
      //   response.data.invoice.lineItems[0].totalTaxLine
      // );
      // console.log(totalTaxLine);
      // if (totalTaxLine === 0) {
      //   uniqueTaxes.forEach((tax) => {
      //     tax.rate = 0;
      //   });
      // }

      uniqueTaxes.forEach((tax) => {
        if (tax.totalTax === 0) {
          tax.rate = 0;
        }
      });


      setUniqueTaxes(uniqueTaxes);
      const totalTax = uniqueTaxes.reduce(
        (total, tax) => total + tax.rate * 100,
        0
      );
      setTotal(totalTax);

      if (
        response.data?.errorMessages &&
        response.data.errorMessages.length > 0
      ) {
        throw new Error(response.data.errorMessages[0].message);
      }
      if (response.data.invoice.lineItems[0]?.errorMessages.length > 0) {
        throw new Error(
          response.data.invoice.lineItems[0].errorMessages[0].message
        );
      }

      setResult(response.data);
    } catch (e) {
      setErr(e.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex h-screen bg-gray-100">
      <div className="flex flex-col mx-auto w-full xs:flex-row">
        <div className="flex flex-col p-2 bg-white shadow-md xs:w-1/2 md:p-6">
          <h2 className="">Calculate</h2>
          <div className="flex flex-grow justify-center items-start">
            <Form submit={submitForm} />
          </div>
        </div>
        <div className="flex flex-col xs:w-1/2 p-2 md:p-6 bg-[#f5f8fa] ">
          <h2 className="border-b-2 border-gray-200">Results</h2>
          <div className="flex flex-grow justify-center items-start pt-8">
            {!loading && !result && !err && (
              <div className="space-y-4 w-full">
                {[...Array(5)].map((_, index) => (
                  <div key={index} className="h-8 bg-gray-200 rounded"></div>
                ))}
              </div>
            )}
            {err && (
              <p className="text-xl font-semibold text-red-400">{err}!</p>
            )}
            {result && (
              <div className="flex flex-col gap-6 w-full">
                <p className="font-bold text-[22px]">Taxes</p>
                <ul className="flex flex-col gap-1">
                  {uniqueTaxes.map((tax) => (
                    <li
                      key={tax.rate + tax.description}
                      className="flex flex-row items-center justify-between gap-2 px-2 py-4 text-base bg-white text-[13px] xs:text-[14px] "
                    >
                      <p>{tax.description}</p>
                      <p>{tax.taxAuthorityName}</p>
                      <p className="font-bold whitespace-nowrap">
                        {(tax.rate * 100).toFixed(3)} %
                      </p>
                    </li>
                  ))}
                </ul>
                <div className="flex justify-between font-bold text-[20px] flex-wrap">
                  <p> Total sales tax rate: </p>
                  <p>{totalTax.toFixed(2)} %</p>
                </div>
              </div>
            )}
            <div className="mt-[20%]">{loading && <HashLoader />}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
